<template>
   <AdviceModal @closeModal="$emit('closeModal')" :disableCross="true">
      <div class="box">
         <div class="box-content">
            <Typography :weigth="'weigth'" :size="'bigger'" class="title">{{ $t('Advice.deleteUserText') }}</Typography>
            <div class="btns">
               <SWButton @click.native="$emit('closeModal')">
                  <Typography class="btnText">{{ $t('Advice.btnCancel') }}</Typography>
               </SWButton>
               <SWButton @click.native="triggerDeleteUser">
                  <Typography class="btnText"> {{ $t('Account.changeStateConfirm') }} </Typography>
               </SWButton>
            </div>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import { SWButton } from 'sw-ui-kit';

import AdviceModal from '../../../components/AdviceComponents/AdviceModal.vue';
// import mutations from '../../../store/mutation-types';
// import axios from 'axios';
import Typography from '../../../components/UI/Typography.vue';
// import store from '../../../store/store';
export default {
   components: {
      AdviceModal,
      Typography,
      SWButton,
   },

   //    mounted() {
   //       this.$store.commit(`profile/${mutations.DELETING_USER_DATA}`, true);
   //    },

   methods: {
      cancelLogout() {
         this.$emit('closeModal');
      },

      async deleteUser(id, token) {

         try {
            const response = await this.$http.post(`/resources/profile/remove`, {
               body: {
                  token: token,
                  user_id: id,
               },
            });
            if (response) {
               localStorage.removeItem('token');
               this.$router.push({ name: 'login' });
            }

            console.log('Дані користувача успішно видалено.');
         } catch (error) {
            console.error('Помилка при видаленні даних користувача:', error.message);
         }
      },

      async triggerDeleteUser() {
         try {
            const data = await this.$http.post('resources/profile');

            const user_id = data.data._id;
            const token = localStorage.user_token;


            await this.deleteUser(user_id, token);
         } catch (error) {
            console.error('Помилка під час видалення користувача:', error);
         }
      },
   },

   //   async deleteUser() {
   //  try {
   //     const { user_id, token } = store.state.user;
   //     console.log(user_id);
   //     console.log(token);
   //     const response = await fetch(`http://sleep-well.coach:8080/resources/profile/remove`, {
   //        method: 'DELETE',
   //        headers: {
   //           'Content-Type': 'application/json',
   //           // Authorization: `Bearer ${token}`,
   //        },
   //        body: JSON.stringify({
   //           token: token,
   //           user_id: user_id,
   //        }),
   //     });
   //     if (!response.ok) {
   //        throw new Error('Не вдалося видалити користувача.');
   //     }
   //     console.log('Дані користувача успішно видалено.');
   //  } catch (error) {
   //     console.error('Помилка при видаленні даних користувача:', error.message);
   //  }
   //   },

   //   async deleteUser() {
   //      try {
   //         const token = localStorage;
   //         const user_id = 'USER_ID_TO_BE_REMOVED';

   //         const response = await fetch(`http://sleep-well.coach:8080/resources/profile/remove`, {
   //            method: 'DELETE',
   //            headers: {
   //               'Content-Type': 'application/json',
   //               //   Authorization: `Bearer ${token}`,
   //            },
   //            body: JSON.stringify({
   //               token: token,
   //               user_id: user_id,
   //            }),
   //         });

   //         if (!response.ok) {
   //            throw new Error('Failed to delete user.');
   //         }

   //         console.log('User data deleted successfully.');
   //      } catch (error) {
   //         console.error('Error deleting user data:', error.message);
   //      }
   //   },

   //    destroyed() {
   //       this.$store.commit(`profile/${mutations.DELETING_USER_DATA}`, false);
   //    },
};
</script>

<style scoped>
.box {
   max-width: 400px;
   width: 100%;
   height: 380px;
   margin: 0 auto;
   background: var(--bg-modal-dark);
}

.title {
   text-align: center;
}
.box-content {
   transform: translateY(120px);
}
.btns {
   margin-top: 60px;
   display: flex;
   justify-content: space-around;
}
.btnText {
   color: white !important;
}
</style>
