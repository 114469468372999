<template>
   <AdviceModal @closeModal="$emit('closeModal')" :disableCross="true">
      <div class="box">
         <div class="box-content">
            <Typography :weigth="'weigth'" :size="'bigger'" class="title">{{ $t('Advice.logoutText') }}</Typography>
            <div class="btns">
               <SWButton @click.native="$emit('closeModal')">
                  <Typography class="btnText">{{ $t('Advice.btnCancel') }}</Typography>
               </SWButton>
               <SWButton @click.native="logout">
                  <Typography class="btnText"> {{ $t('Advice.btnLogout') }} </Typography>
               </SWButton>
            </div>
         </div>
      </div>
   </AdviceModal>
</template>

<script>
import { SWButton } from 'sw-ui-kit';

import AdviceModal from '../../../components/AdviceComponents/AdviceModal.vue';
import mutations from '../../../store/mutation-types';
import Typography from '../../../components/UI/Typography.vue';
export default {
   components: {
      AdviceModal,
      Typography,
      SWButton,
   },

   mounted() {
      this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, true);
   },

   methods: {
      logout() {
         localStorage.user_token = '';
         localStorage.removeItem('social_img');
         sessionStorage.isAuth = false;
         sessionStorage.isAuthUser = false;
         if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
               type: 'logout',
            });
         }
         sessionStorage.clear();
         this.$emit('closeModal');
         this.$router.push({ name: 'login' });
      },
   },

   destroyed() {
      this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, false);
   },
};
</script>

<style scoped>
.box {
   max-width: 400px;
   width: 100%;
   height: 380px;
   margin: 0 auto;
   background: var(--bg-modal-dark);
}

.title {
   text-align: center;
}
.box-content {
   transform: translateY(120px);
}
.btns {
   margin-top: 60px;
   display: flex;
   justify-content: space-around;
}
.btnText {
   color: white !important;
}
</style>
