<template>
   <div class="modal" @click="closeModal">
      <div class="modal__close">
         <button class="modal__close" @click="closeModal" v-if="!disableCross">
            <svg><use xlink:href="../../assets/img/icons.svg#cross-icon"></use></svg>
         </button>
      </div>
      <div class="modal__content" @click.stop>
         <slot></slot>
      </div>
   </div>
</template>

<script>
import mutations from '../../store/mutation-types';
export default {
   props: {
      disableCross: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      currentTheme() {
         return this.$store.getters['advice/getCurrentTheme'];
      },
   },

   mounted() {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = `0`;
      document.body.style.width = `100%`;
      document.body.style.position = 'fixed';

      this.$store.commit(`advice/${mutations.SET_IS_MODAL_OPEN}`, true);
   },

   destroyed() {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.body.style.width = `unset`;
      this.$store.commit(`advice/${mutations.SET_IS_MODAL_OPEN}`, false);
   },

   methods: {
      closeModal() {
         this.$emit('closeModal');
      },
   },
};
</script>

<style scoped>
.modal {
   height: 100%;
   width: 100%;
   position: fixed;
   z-index: 1000;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.2);
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.modal__content {
   background-color: var(--bg-modal-dark);
   height: 100%;
   width: 100%;
   padding: 10px 30px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   overflow-y: auto;
}
.modal__close {
   z-index: 1;
   position: absolute;
   top: 10px;
   right: 10px;
   right: 50%;
   transform: translateX(50%);
   padding: 0 15px;
   max-width: 1330px;
   width: 100%;
   text-align: right;
}
.modal__close svg {
   width: 20px;
   height: 20px;
}
@media (max-width: 600px) {
   .modal__content {
      padding: 5px 0;
   }
}
</style>
