<template>
   <div class="content__menu">
      <router-link :to="{ name: 'account' }">
         <div class="menu__linck_account">
            <Typography :weigth="'weigth'" :size="'bigger'">
               <span class="cursorePointer">Account</span>
            </Typography>
            <div class="img">
               <!-- <img src="../../../assets/img/layoutArrow.svg" alt="" /> -->
               <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M9.42609 0.573817C9.69946 0.30045 10.1427 0.30045 10.416 0.573817L15.8466 6.00436C16.12 6.27773 16.12 6.72094 15.8466 6.99431L10.416 12.4249C10.1427 12.6982 9.69946 12.6982 9.42609 12.4249L8.82505 11.8238C8.55169 11.5504 8.55169 11.1072 8.82505 10.8339L12.0352 7.62376H0.751786C0.406009 7.62376 0.125 7.32114 0.125 6.94876V6.04876C0.125 5.67639 0.406009 5.37376 0.751786 5.37376H12.034L8.82505 2.16481C8.55169 1.89144 8.55169 1.44823 8.82505 1.17486L9.42609 0.573817Z"
                  />
               </svg>
            </div>
         </div>
      </router-link>
      <button  class="menu__linck_installApp" @click="callPWA">
         <Typography :weigth="'weigth'" :size="'bigger'"
            ><span class="cursorePointer"> Install Sleep Well Lite App</span>
         </Typography>
         <div class="img imgCurrent">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
               <g clip-path="url(#clip0_1268_12878)">
                  <path
                     d="M10.0827 6.91699H5.49935C5.01312 6.91699 4.5468 7.11015 4.20299 7.45396C3.85917 7.79778 3.66602 8.2641 3.66602 8.75033V17.0003C3.66602 17.4866 3.85917 17.9529 4.20299 18.2967C4.5468 18.6405 5.01312 18.8337 5.49935 18.8337H13.7493C14.2356 18.8337 14.7019 18.6405 15.0457 18.2967C15.3895 17.9529 15.5827 17.4866 15.5827 17.0003V12.417"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M9.16602 13.3337L18.3327 4.16699"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M13.75 4.16699H18.3333V8.75033"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </g>
               <defs>
                  <clipPath id="clip0_1268_12878">
                     <rect width="22" height="22" transform="translate(0 0.5)" />
                  </clipPath>
               </defs>
            </svg>
         </div>
      </button>

      <router-link :to="{ name: 'pricing' }">
         <div class="menu__pricing">
            <Typography :weigth="'weigth'" :size="'bigger'">
               <span class="cursorePointer"> Pricing </span>
            </Typography>
            <div class="img">
               <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M9.42609 0.573817C9.69946 0.30045 10.1427 0.30045 10.416 0.573817L15.8466 6.00436C16.12 6.27773 16.12 6.72094 15.8466 6.99431L10.416 12.4249C10.1427 12.6982 9.69946 12.6982 9.42609 12.4249L8.82505 11.8238C8.55169 11.5504 8.55169 11.1072 8.82505 10.8339L12.0352 7.62376H0.751786C0.406009 7.62376 0.125 7.32114 0.125 6.94876V6.04876C0.125 5.67639 0.406009 5.37376 0.751786 5.37376H12.034L8.82505 2.16481C8.55169 1.89144 8.55169 1.44823 8.82505 1.17486L9.42609 0.573817Z"
                  />
               </svg>
            </div>
         </div>
      </router-link>
      <button @click="openModalDeleteUser = true" class="menu__logOut menu__pricing">
         <Typography :weigth="'weigth'" :size="'bigger'">
            <span class="cursorePointer"> Deleting user data</span>
         </Typography>
         <div class="img">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.42609 0.573817C9.69946 0.30045 10.1427 0.30045 10.416 0.573817L15.8466 6.00436C16.12 6.27773 16.12 6.72094 15.8466 6.99431L10.416 12.4249C10.1427 12.6982 9.69946 12.6982 9.42609 12.4249L8.82505 11.8238C8.55169 11.5504 8.55169 11.1072 8.82505 10.8339L12.0352 7.62376H0.751786C0.406009 7.62376 0.125 7.32114 0.125 6.94876V6.04876C0.125 5.67639 0.406009 5.37376 0.751786 5.37376H12.034L8.82505 2.16481C8.55169 1.89144 8.55169 1.44823 8.82505 1.17486L9.42609 0.573817Z"
               />
            </svg>
         </div>
      </button>
      <button @click="openModalLogOut = true" class="menu__logOut">
         <Typography :weigth="'weigth'" :size="'bigger'"> <span class="cursorePointer"> Log out</span> </Typography>
         <div class="img">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
               <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.42609 0.573817C9.69946 0.30045 10.1427 0.30045 10.416 0.573817L15.8466 6.00436C16.12 6.27773 16.12 6.72094 15.8466 6.99431L10.416 12.4249C10.1427 12.6982 9.69946 12.6982 9.42609 12.4249L8.82505 11.8238C8.55169 11.5504 8.55169 11.1072 8.82505 10.8339L12.0352 7.62376H0.751786C0.406009 7.62376 0.125 7.32114 0.125 6.94876V6.04876C0.125 5.67639 0.406009 5.37376 0.751786 5.37376H12.034L8.82505 2.16481C8.55169 1.89144 8.55169 1.44823 8.82505 1.17486L9.42609 0.573817Z"
               />
            </svg>
         </div>
      </button>

      <ModalLogOutAdvice @closeModal="openModalLogOut = false" v-if="openModalLogOut" />
      <ModalDeletingUserData @closeModal="openModalDeleteUser = false" v-if="openModalDeleteUser" />
   </div>
</template>

<script>
import Typography from '../../../components/UI/Typography.vue';
import mutations from '../../../store/mutation-types';
import ModalDeletingUserData from './ModalDeletingUserData.vue';
import ModalLogOutAdvice from './ModalLogOutAdvice.vue';

export default {
   components: { Typography, ModalLogOutAdvice, ModalDeletingUserData },
   inject: ['installPWA'],
   data() {
      return {
         openModalLogOut: false,
         openModalDeleteUser: false,
      };
   },
   mounted() {
      this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, true);
   },
   //! here is a crutch for Install Sleep Well Lite App button in settings. Don't want to show it if pwa installed already
/*    computed: {
      showInstall() {
         console.log('do not show', localStorage.showInstall);
         if (localStorage.showInstall === 'false') {
            return false;
         } else {
            return true;
         }
      },
   }, */
   //
   methods: {
      callPWA() {
         this.installPWA();
      },
      // logOut() {
      //    this.openModalLogOut = true;

      // },
      // logout() {
      //    localStorage.user_token = '';
      //    sessionStorage.isAuth = false;
      //    sessionStorage.isAuthUser = false;
      //    if (navigator.serviceWorker.controller) {
      //       navigator.serviceWorker.controller.postMessage({
      //          type: 'logout',
      //       });
      //    }
      //       sessionStorage.clear();
      //       this.$emit('closeModal');
      //       this.$router.push({ name: 'login' });
      //    },
      // },

      // destroyed() {
      //    this.$store.commit(`profile/${mutations.SET_LOG_OUT_OPEN}`, false);
      // },
   },
};
</script>

<style>
.profile {
   display: flex;
   max-width: 876px;
   margin: 0 auto;
   padding-top: 70px;
}
.content__menu {
   display: flex;
   width: 100%;
   flex-direction: column;
   font-size: 24px;
   font-weight: 700;
   line-height: 31px;
}
.menu__linck_account,
.menu__linck_installApp,
.menu__pricing {
   border-bottom: 0.5px solid var(--typograohy-color);
}
.menu__linck_account,
.menu__linck_installApp,
.menu__pricing,
.menu__logOut {
   padding: 32px 5px;
   position: relative;
   width: 100%;
}
.menu__linck_installApp,
.menu__logOut {
   display: flex;
}

.img {
   position: absolute;
   right: 16px;
   bottom: 33px;
}
.img svg {
   fill: var(--typograohy-color);
}
.imgCurrent svg {
   fill: transparent;
   stroke: var(--typograohy-color);
}
.imgCurrent {
   bottom: 29px;
}
.cursorePointer {
   cursor: pointer;
}
/* @media screen and (max-width: 450px) {
   .header_layout {
      display: none;
   }
} */

/* .profile
   padding-top: 50px

   &__box
      margin: 10px auto 0
      padding: 0 20px 20px
      max-width: 1300px
      width: 100%

   &__img
      margin-bottom: 10px

   &__discount
      margin: 20px
      font-size: 1.1rem
      font-weight: 400

      & span
         font-weight: 300
         font-size: 1rem

   & .env-settings
      margin-left: 40px
      display: flex
      border-bottom: 1px solid #f1f1f1
      padding-bottom: 10px
      align-items: center
      flex-wrap: wrap

      & > *:first-child
         margin-left: -40px
         margin-right: 30px

@media (max-width:600px)
   .profile .env-settings
      justify-content: space-between

      & > *:first-child
         margin-left: -40px
         margin-right: 10px

@media (max-width:364px)
   .profile .env-settings
      display: block

      & > *:first-child, & > *:last-child
         margin-left: -40px
         margin-right: 0
      & > *:last-child
         margin-top: 20px

#sepia .profile .env-settings
   border-bottom: 1px solid $app-sepia-border

#dark .profile .env-settings
   border-bottom: 1px solid #f1f1f1

.change-password
   text-align: right
   margin-top: 20px

.instructions
   width: 100%
   display: flex
   align-items: center
   margin-left: auto
   margin-right: auto
   padding: 10px 0
   cursor: pointer
   transform: scale(1)
   transition: all 0.3s
   color: inherit
   text-decoration: none

   & > p
      padding: 10px 0
      width: 100%
      border-bottom: 1px solid #f1f1f1

   & > *
      cursor: pointer

   & svg
      fill: #76c4ba
      stroke: #76c4ba
      width: 25px
      height: 25px
      margin-right: 20px

button.instructions
   text-align: left

.instructions.messagers > *
   cursor: default

.instructions.messagers:hover:not(.instructions.messagers:active)
   cursor: default
   transform: none

.cancel-subscription
   margin-top: 20px

   & *
      cursor: pointer

@media (max-width: 768px)
   .cancel-subscription
      margin: 20px auto 0

#sepia .profile .instructions p
   border-bottom: 1px solid $app-sepia-border

#dark .profile .instructions p
   border-bottom: 1px solid #f1f1f1

.box
   max-width: 380px
   width: 100%
   margin: 0 auto

.title
   text-align: center

.btns
   margin-top: 40px
   display: flex
   justify-content: space-between */
</style>
